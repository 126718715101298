import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, /*faTwitter,*/ faInstagram, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { colors, sizes } from '../../utils/constants'
import Delayed from './Delayed'
import logo from '../../assets/logo-white.png'

export const Header = ({ timeDelay }) => {
    const [color] = useState(colors.black)
    
    // const listenScrollEvent = e => {
    //     if (window.scrollY > 2) {
    //         setColor(colors.black)
    //     } else {
    //         setColor(colors.transparent)
    //     }
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', listenScrollEvent)
    // }, [])
    
    return (
        <Delayed waitBeforeShow={timeDelay}>
            <HeaderContainer color={color}>
                <MainMenu>
                    <MainMenuWrapper>
                        <WrapperLogo>
                            <Image src={logo} alt="" width={150}/>
                        </WrapperLogo>
                        <MainMenuWrapperRight>
                            <SocialBox>
                                <WrapperSocial>
                                    <a href={"https://www.facebook.com/canadacv"} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook}/></a>
                                    {/* <a href={"https://twitter.com/CvCanada"}><FontAwesomeIcon icon={faTwitter}/></a> */}
                                    <a href={"https://www.instagram.com/cvcanada"} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                                    <a href={"https://www.linkedin.com/company/cv-canada-immigration"} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin}/></a>
                                    <a href={"https://wa.me/17809005253"} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp}/></a>
                                </WrapperSocial>
                            </SocialBox>
                        </MainMenuWrapperRight>
                    </MainMenuWrapper>
                </MainMenu>
            </HeaderContainer>
        </Delayed>
    );
}

const HeaderContainer = styled.div`
    overflow: hidden;
    padding: 0 60px;
    background: ${props => props.color};
    position: fixed;
    top: 0px;
    width:100%;
    z-index: 91;
    transition: all 500ms ease;

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        content: "";
        background-color: rgba(${colors.base_rgb}, .15);
    }

    @media (max-width: ${sizes.mobileL}px) {
        padding: 0 30px;
    }
`

const MainMenu = styled.nav`
    position: relative;
    z-index: 91;
`

const MainMenuWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: space-between;
    justify-content: space-between;
`

const MainMenuWrapperRight = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding-right: 30px;

    @media (max-width: 576px) {
        display: none;
    }
`

const SocialBox = styled.div`
    position: relative;
    display: block;
    float: left;
`

const WrapperSocial = styled.div`
    display: flex;
    align-items: center;
    padding: 19px 0;

    & a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: ${colors.base};
        font-size: 17px;
        transition: all 500ms ease;

        &:hover {
            color: ${colors.primary};
        }
    }

    & a+a {
        margin-left: 25px;
    }
`

const WrapperLogo = styled.div`
    flex: 0 0 auto;
`

const Image = styled.img`
    vertical-align: bottom;
`