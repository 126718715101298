import { createContext, useContext, useMemo, useState } from "react"

const SplashContext = createContext()

export const SplashProvider = props => {
    const [isLoaded, setLoaded] = useState(false)
  
    const value = useMemo(() => {
      return {
        isLoaded,
        setLoaded
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded])
  
    return <SplashContext.Provider value={value} {...props} />
  }
  
  export const useSplash = () => {
    const context = useContext(SplashContext)
    if (!context) {
      throw new Error(
        "useInputSearch debe estar dentro del proveedor SearchProvider"
      )
    }
    return context
  }