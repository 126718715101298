import styled from 'styled-components'
import { colors } from '../../utils/constants'
import logo from '../../assets/logo-white.png'

export const SplashScreen = () => {
    return (
        <Container>
            <ImageSplash src={logo} width={"auto"} height={"auto"} />
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    z-index: 100;
`

const ImageSplash = styled.img`
    width: 40%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`