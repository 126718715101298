import styled from "styled-components"
import { useTranslation } from 'react-i18next'
import { colors, devices, letterSpacings, sizes } from "../../utils/constants"
import { Row } from "../../utils/styles"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneSquare, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { faFacebook, /*faTwitter,*/ faInstagram, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import Delayed from './Delayed'
import logo from '../../assets/logo-white.png'
import rcicLogo from '../../assets/rcic.png'

export const Footer = ({ timeDelay }) => {
    const { t } = useTranslation()
    const today = new Date()
    return (
        <Delayed waitBeforeShow={timeDelay}>
            <FooterDiv>
                <FooterContainer>
                    <FooterTop>
                        <Row>
                            <Column1>
                                <InfoDiv>
                                    <LogoDiv><ImageLink to="/"><Logo src={logo} width={150}/></ImageLink><Logo src={rcicLogo} width={220} height={"auto"}/></LogoDiv>
                                </InfoDiv>
                                <FooterText>{t('footer.about_us')}</FooterText>
                                <List>
                                    <ListItem>
                                        <div><FontAwesomeIcon icon={faPhoneSquare} style={{ color: colors.primary}}/></div>
                                        <ItemText><Paragraph><LinkA href="tel:7809005253">+1 (780)-900-5253</LinkA></Paragraph></ItemText>
                                    </ListItem>
                                    <ListItem>
                                        <div><FontAwesomeIcon icon={faEnvelope} style={{ color: colors.primary}}/></div>
                                        <ItemText><Paragraph><LinkA href="mailto:info@cvcanadaimmigration.com">info@cvcanadaimmigration.com</LinkA></Paragraph></ItemText>
                                    </ListItem>
                                    <ListItem>
                                        <div><FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: colors.primary}}/></div>
                                        <ItemText><Paragraph><LinkA href="https://www.google.com.mx/maps/place/10448+176+St+NW,+Edmonton,+AB+T5S+1L3,+Canada">Suite 201 10448 176 St NW, Edmonton, AB T5S 1L3</LinkA></Paragraph></ItemText>
                                    </ListItem>
                                </List>
                            </Column1>
                            <Column2>
                                <div>
                                    <FooterTitle>{t('footer.links')}</FooterTitle>
                                    <LinksList>
                                        <ListItem><Paragraph><LinkPage to="/">{t('footer.privacy_policy')}</LinkPage></Paragraph></ListItem>
                                    </LinksList>
                                </div>
                            </Column2>
                    </Row>
                    </FooterTop>
                    <FooterBottom>
                        <Row>
                            <FooterBottomContent>
                                <FooterItem>
                                    <Paragraph>© Copyright { today.getFullYear() } by CVCanada Immigration</Paragraph>
                                </FooterItem>
                                <FooterItem2>
                                    <FooterSocial>
                                        <a href={"https://www.facebook.com/canadacv"} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook}/></a>
                                        {/* <a href={"https://twitter.com/CvCanada"}><FontAwesomeIcon icon={faTwitter}/></a> */}
                                        <a href={"https://www.instagram.com/cvcanada"} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                                        <a href={"https://www.linkedin.com/company/cv-canada-immigration"} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin}/></a>
                                        <a href={"https://wa.me/17809005253"} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp}/></a>
                                    </FooterSocial>
                                </FooterItem2>
                            </FooterBottomContent>
                        </Row>
                    </FooterBottom>
                </FooterContainer>
            </FooterDiv>
        </Delayed>
    )
}

const FooterDiv = styled.div`
    position: relative;
    display: block;
    background-color: ${colors.black};
`

const FooterContainer = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;

    @media ${devices.laptopL} {
        max-width: 1200px;
    }

    @media ${devices.tablet} {
        max-width: 90%;
    }
`

const FooterTop = styled.div`
    position: relative;
    display: block;
    padding: 105px 0 60px;
`

const FooterBottom = styled.div`
    position: relative;
    display: block;
    border-top: 1px solid rgba(${colors.base_rgb}, .05);
    padding: 30px 0;
`

const Column1 = styled.div`
    @media ${devices.laptopL} {
        flex: 0 0 auto;
        width: 60%;
    }

    @media ${devices.tablet} {
        flex: 0 0 auto;
        width: 60%;
    }
`

const Column2 = styled.div`
    @media ${devices.laptopL} {
        flex: 0 0 auto;
        width: 35%;
    }

    @media ${devices.tablet} {
        flex: 0 0 auto;
        width: 25%;
    }
`

const InfoDiv = styled.div`
    position: relative;
    display: block;
    margin-top: -10px;
    margin-right: 70px;
`

const LogoDiv = styled.div`
    position: relative;
    display: block;
    margin-bottom: 35px;
    display: flex;
    flex-direction: row;
    gap: 16px;
`

const ImageLink = styled(Link)`
    text-decoration: none;
    color: ${colors.gray};
`

const Logo = styled.img`
    vertical-align: middle;
    min-width: 110px;
`

const FooterTitle = styled.h3`
    font-size: 18px;
    font-weight: 800;
    line-height: 28px;
    text-transform: uppercase;
    color: ${colors.base};
    margin-bottom: 32px;
    letter-spacing: ${letterSpacings.small};
`

const FooterText = styled.p`
    font-size: 16px;
    color: #8f8da0;
    margin: 0;
    font-weight: 500;
`

const List = styled.ul`
    position: relative;
    display: block;
    border-top: 1px solid rgba(${colors.base_rgb}, .10);
    margin-top: 18px;
    padding-top: 20px;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
`

const LinksList = styled.ul`
    position: relative;
    display: block;
    float: left;
    padding-left: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
`

const ListItem = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 8px;
`

const ItemText = styled.div`
    margin-left: 20px;
    & p,a {
        margin: 0;
        font-size: 16px;
        color: ${colors.gray};
    }
`

const Paragraph = styled.p`
    margin: 0;
    font-size: 16px;
    color: ${colors.gray};
    font-weight: 500;
    transition: all 500ms ease;
`

const LinkA = styled.a`
    margin: 0;
    font-size: 16px;
    color: ${colors.gray};
    font-weight: 500;
    transition: all 500ms ease;

    &:hover {
        color: ${colors.base};
    }
`

const LinkPage = styled(Link)`
    margin: 0;
    font-size: 16px;
    color: ${colors.gray};
    font-weight: 500;
    transition: all 500ms ease;

    &:hover {
        color: ${colors.base};
    }
`

const FooterBottomContent = styled.div`
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    justify-content: space-between;
`

const FooterItem = styled.div`
    flex: 0 0 auto;
    width: 70%;

    @media (max-width: 567px) {
        width: 100%;
    }
`

const FooterItem2 = styled.div`
    flex: 0 0 auto;
    width: 30%;

    @media (max-width: 567px) {
        width: 100%;
    }
`

const FooterSocial = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;

    @media (max-width: ${sizes.mobileL}px) {
        justify-content: center;
    }

    & a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: ${colors.gray};
        font-size: 17px;
        transition: all 500ms ease;

        &:hover {
            color: ${colors.base};
        }
    }

    & a+a {
        margin-left: 25px;
    }
`