import React, { lazy, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import Splashr from 'splashr';
import { SplashScreen } from './components/context/Splash';
import { Header } from './components/context/Header';
import { Footer } from './components/context/Footer';
// import Appointment from './pages/Appointment'
import './App.css';

const Appointment = lazy(() => import('./pages/Appointment'));

function App() {
  const timeDelaySplash = 100;
  const timeDelay = 100;
  const AppRef = useRef(null);

  return (
    <Splashr.Suspense splash={<SplashScreen />} minDelay={timeDelaySplash}>
      <div className='App' id='App' ref={AppRef}>
        <Header timeDelay={timeDelay} />
        <Routes>
          <Route
            exact
            path='/'
            element={<Appointment timeDelay={timeDelay} AppRef={AppRef} />}
          />
          {/* <Route path='/*' element={<Navigate replace to='/' />} /> */}
          <Route
            exact
            path='/:token'
            element={<Appointment timeDelay={timeDelay} AppRef={AppRef} />}
          />
        </Routes>
        <Footer timeDelay={timeDelay}></Footer>
      </div>
    </Splashr.Suspense>
  );
}

export default App;
