import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { SplashProvider } from './context/SplashContext';
import FloatingButton from './components/buttons/FloatingButton';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import './i18n';
import App from './App';

Sentry.init({
  dsn: "https://3f3a4c2fa3024c72b30e704035343452@o4505477838536704.ingest.sentry.io/4505477840437248",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://appointment.cvcanadaimmigration.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const container = document.getElementById('root')

const root = createRoot(container)

root.render(
  <SplashProvider>
    <BrowserRouter>
      <FloatingButton
          icon={faWhatsapp}
          link="https://api.whatsapp.com/send?phone=17809005253&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20CVCanadaImmigration"
        />
      <App />
    </BrowserRouter>
  </SplashProvider>
);