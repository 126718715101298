import styled from "styled-components"

export const Row = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: calc(30px/ -2);
    margin-left: calc(30px/ -2);

    >* {
        max-width: 100%;
        padding-right: calc(30px/ 2);
        padding-left: calc(30px/ 2);
        margin-top: 0;
    }
`