import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSplash } from '../../context/SplashContext';

// class Delayed extends React.Component {

//     constructor(props) {
//         super(props);
//         this.state = {hidden : true};
//     }

//     componentDidMount() {
//         setTimeout(() => {
//             this.setState({hidden: false});
//         }, this.props.waitBeforeShow);
//     }

//     render() {
//         return this.state.hidden ? '' : this.props.children;
//     }
// }

const Delayed = (props) => {
    const [hidden, setHidden] = useState(true)
    const { isLoaded, setLoaded} = useSplash()

    useEffect(() => {
        if(isLoaded) setHidden(false)
        else {
            setTimeout(() => {
                setHidden(false)
                setLoaded(true)
            }, props.waitBeforeShow);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return hidden ? '' : props.children
}

Delayed.propTypes = {
  waitBeforeShow: PropTypes.number.isRequired
};

export default Delayed;