export const sizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1200,
  laptopL: 1440,
  desktop: 2560
}

export const devices = {
  mobileS: `(min-width: ${sizes.mobileS}px)`,
  mobileM: `(min-width: ${sizes.mobileM}px)`,
  mobileL: `(min-width: ${sizes.mobileL}px)`,
  tablet: `(min-width: ${sizes.tablet}px)`,
  laptop: `(min-width: ${sizes.laptop}px)`,
  laptopL: `(min-width: ${sizes.laptopL}px)`,
  desktop: `(min-width: ${sizes.desktop}px)`,
  desktopL: `(min-width: ${sizes.desktop}px)`
}

export const colors = {
  primary: "#D70607",
  black: "#162e40",
  blue: "#2d5883",
  blue_light: "#7BA7D3",
  gray: "#726f84",
  gray_light: "#e4e4e4",
  gray_lighter: "#F5F5F5",
  base: "#ffffff",
  light: "#f2f4f8",
  transparent: "transparent",
  base_rgb: "255, 255, 255",
  black_rgb: "12, 33, 54"
}

export const letterSpacings = {
  big: "0.1em",
  normal: "0.02em",
  small: "-0.02em"
}