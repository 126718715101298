import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FloatingButton = (props) => (
    <Link href={props.link} target="_blank"><FontAwesomeIcon icon={props.icon}></FontAwesomeIcon></Link>
)

const Link = styled.a`
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #4fed8a;
    }
`

export default FloatingButton